<template>
  <div>
    <ca-header
      heading="HSN/TSN"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          class="btn btn-primary"
          :to="{ name: 'HsnTsnCreate' }"
        >
          Neue HSN/TSN hinzufügen
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="hsntsns"
        title="HSN/TSN"
        @row-clicked="
          (item) =>
            $router.push({ name: 'HsnTsnEdit', params: { hsntsnId: item._id } })
        "
      />
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatNumberLength from '../../filters/formatNumberLength.js'
export default {
  name: 'HsnTsnList',
  components: {
    DataTable,
    CaHeader
  },
  data: () => ({
    fields: [
      {
        key: 'hsn',
        label: 'HSN',
        filter: {
          type: 'SEARCH',
          regex: false
        }
      },
      {
        key: 'tsn',
        label: 'TSN',
        filter: { type: 'SEARCH' }
      },
      {
        key: 'typ',
        label: 'Typ'
      },
      {
        key: 'producer',
        label: 'Hersteller'
      },
      {
        key: 'ccm',
        label: 'CCM',
        formatter: value => formatNumberLength(value, 5)
      },
      {
        key: 'kw',
        label: 'kW',
        formatter: value => formatNumberLength(value, 4)
      },
      {
        key: 'bj_start',
        label: 'Baujahr Start'
      },
      {
        key: 'bj_end',
        label: 'Baujahr Ende'
      },
      {
        key: 'wfs',
        label: 'WFS'
      }
    ]
  })
}
</script>

<style scoped lang="scss">
</style>
